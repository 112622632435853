<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Internal Docs" backTo="internal">
      <button
        @click="showingUploadDocumentPanel = true"
        class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M17 8l-5-5-5 5M12 3v12"
          />
        </svg>
        <span>Upload Document</span>
      </button>
    </page-header>
    <main class="p-6 flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <form @submit.prevent="performSearch" class="mb-4 flex justify-end">
          <input
            type="text"
            class="lg:ml-10 w-full md:w-1/2 lg:w-1/3 rounded-l bge-input bge-input-spacing"
            placeholder="Search documents"
            v-model="searchTerm"
          />
          <div class="relative">
            <select
              class="bge-input bge-select rounded-none h-full"
              v-model="categoryFilter"
              @change="onCategoryFilterChanged"
            >
              <option value="Internal">Internal</option>
              <option>Non-Disclosure Agreement</option>
              <option>Business Plan</option>
              <option>Business Reports</option>
              <option>Financial Documents</option>
              <option>Compliance and Regulatory Documents</option>
              <option>Business Insurance Documents</option>
              <option>Company Bylaws</option>
              <option>Business Registration </option>
              <option>Business License</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                <path
                  d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                />
              </svg>
            </div>
          </div>
          <button
            v-if="isShowingSearchResults"
            @click="clearSearch"
            type="button"
            class="bg-red-200 hover:bg-red-300 border border-red-400 hover:border-red-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
          >
            <svg
              class="stroke-current h-5 w-5"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M18 6L6 18M6 6l12 12" />
            </svg>
          </button>
          <button
            type="submit"
            class="rounded-r bg-blue-200 hover:bg-blue-300 border border-blue-400 hover:border-blue-500 p-2 hover:shadow active:shadow-inner transition-colors duration-300 ease-in-out"
          >
            <svg
              class="stroke-current h-5 w-5"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              viewBox="0 0 24 24"
            >
              <circle cx="11" cy="11" r="8" />
              <path d="M21 21l-4.35-4.35" />
            </svg>
          </button>
        </form>
        <div v-if="viewState == 'Idle'">
          <Table
            v-if="publishedDocs.length > 0"
            :columns="columns"
            :data="publishedDocs"
            :totalRecords="paginationOptions.totalRecords"
            :hasPagination="true"
            :currentPage="paginationOptions.currentPage"
            :totalPages="paginationOptions.totalPages"
            :initialSortOptions="sortOptions"
            @onSortChanged="onTableSortChanged"
            @onPageChanged="onTablePageChanged"
          >
            <template v-slot:actions="props">
              <div class="flex justify-end space-x-2">
                <button
                  v-if="props.row.content_type == 'application/pdf'"
                  @click="viewDoc(props.row)"
                  type="button"
                  class="flex items-center bg-orange-200 border hover:bg-orange-300 border-orange-400 hover:border-orange-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                >
                  <svg
                    class="stroke-current h-5 w-5"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <circle cx="11" cy="11" r="8" />
                    <path d="M21 21l-4.35-4.35" />
                  </svg>
                </button>
                <button
                  @click="downloadDoc(props.row)"
                  class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                  name="Edit Published Doc"
                >
                  <svg
                    class="stroke-current h-5 w-5"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3"
                    />
                  </svg>
                </button>
                <button
                  v-if="canEditDelete"
                  @click="editDoc(props.row)"
                  class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                  name="Edit Published Doc"
                >
                  <svg
                    class="stroke-current h-5 w-5"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                    />
                  </svg>
                </button>
                <button
                  v-if="canEditDelete"
                  @click="deleteDoc(props.row)"
                  class="flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md p-1"
                  name="Delete Published Doc"
                >
                  <svg
                    class="stroke-current h-5 w-5"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                    />
                  </svg>
                </button>
              </div>
            </template>
          </Table>
          <div v-else class="text-center py-24">
            <p>There are no documents to show.</p>
          </div>
        </div>
        <div
          v-if="viewState == 'Loading'"
          class="flex items-center justify-center py-20"
        >
          <spinner :color="'text-black'" :size="10" />
        </div>
      </div>
    </main>
    <portal to="overlay-outlet">
      <Panel
        :showing="showingUploadDocumentPanel"
        @close="closePanel"
        :title="editingDoc.document_id ? 'Edit Document' : 'Upload Document'"
      >
        <UploadPublishedDoc :document="editingDoc" @complete="onComplete" />
      </Panel>
    </portal>
    <portal to="overlay-outlet">
      <ConfirmAlert
        :showingConfirm="confirmAlert.showing"
        :message="confirmAlert.message"
        @complete="confirmAlert.resultFunction"
      />
    </portal>
    <portal to="overlay-outlet">
      <ViewDocument
        :showing="viewingDocument.panelOpen"
        :document="viewingDocument.doc"
        @close="viewingDocument.panelOpen = false"
      />
    </portal>
  </div>
</template>

<script>
const PageHeader = () => import("@/components/PageHeader.vue");
const Alert = () => import("@/components/Alert.vue");
const Table = () => import("@/components/Table.vue");
const Spinner = () => import("@/components/Spinner.vue");
const Toggle = () => import("@/components/Toggle.vue");
const ConfirmAlert = () => import("@/components/ConfirmAlert.vue");
const UploadPublishedDoc = () =>
  import("@/components/InternalDocs/UploadPublishedDoc.vue");
const Panel = () => import("@/components/Panel.vue");
const ViewDocument = () => import("@/components/ViewDocument.vue");

export default {
  name: "InternalDocuments",
  components: {
    PageHeader,
    Alert,
    Table,
    Spinner,
    Toggle,
    ConfirmAlert,
    UploadPublishedDoc,
    Panel,
    ViewDocument,
  },
  data() {
    return {
      viewState: "Idle",
      showingUploadDocumentPanel: false,
      searchTerm: "",
      categoryFilter: "Internal",
      isShowingSearchResults: false,
      publishedDocs: [],
      editingDoc: {
        visibility: "Internal",
      },
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      },
      paginationOptions: {
        currentPage: 1,
        totalPages: 1,
        totalRecords: 0,
      },
      sortOptions: {
        field: "created_at",
        isAsc: false,
        pageSize: 50,
      },
      columns: [
        {
          title: "Document Number",
          field: "document_number",
          selector: (row) => row.document_number,
          widthClass: "w-64",
        },
        {
          title: "Name",
          field: "name",
          selector: (row) => row.name,
        },
        {
          title: "Category",
          field: "category",
          selector: (row) => row.category,
        },
        {
          title: "Created",
          field: "created_at",
          selector: (row) => row.created_at,
          type: "date",
        },
        {
          field: "actions",
          type: "custom",
          sortable: false,
          widthClass: "w-8",
        },
      ],
      viewingDocument: {
        doc: {},
        panelOpen: false,
      },
    };
  },
  computed: {
    canEditDelete: function() {
      return this.$store.state.userRoles.includes("hr_manager") ? true : false;
    },
  },
  async mounted() {
    try {
      this.viewState = "Loading";
      await this.loadDocuments();
      this.viewState = "Idle";
    } catch (error) {
      console.error(error);
      this.viewState = "Error";
      this.error = error;
    }
  },
  methods: {
    selectTab: function(tabName) {
      this.$nextTick(() => {
        this.currentTab = tabName;
      });
      // this.$store.dispatch("setContactSearchTerm", "");
    },
    ticketCreated: async function() {
      await this.loadDocuments();
      this.showingUploadDocumentPanel = false;
    },
    loadDocuments: async function() {
      this.viewState = "Loading";
      try {
        let result = await this.InternalDocumentService.getDocuments({
          ...this.paginationOptions,
          ...this.sortOptions,
          category: this.categoryFilter,
        });
        console.log(result);
        this.publishedDocs = result.data;
        this.paginationOptions.currentPage = result.page;
        this.paginationOptions.totalPages = result.page_count;
        this.paginationOptions.totalRecords = result.count;
      } catch (error) {
        console.error(error);
      } finally {
        this.viewState = "Idle";
      }
    },
    viewDoc: async function(document) {
      console.log(document);
      let doc = await this.InternalDocumentService.getDocumentById(
        document.document_id
      );
      this.viewingDocument = {
        doc: doc,
        panelOpen: true,
      };
    },
    downloadDoc: async function(document) {
      try {
        let doc = await this.InternalDocumentService.getDocumentById(
          document.document_id
        );
        window.open(doc.access_url);
        console.log(doc);
      } catch (error) {
        console.log(error);
      }
    },
    editDoc: function(row) {
      console.log(row);
      this.editingDoc = row;
      this.showingUploadDocumentPanel = true;
    },
    deleteDoc: async function(document) {
      this.confirmAlert = {
        resultFunction: this.handleDeleteDoc,
        message:
          "Are you sure you want to delete this document? Cannot be undone",
        showing: true,
        data: document,
      };
    },
    handleDeleteDoc: async function(result) {
      if (result) {
        try {
          let result = await this.InternalDocumentService.deleteDocument(
            this.confirmAlert.data.document_id
          );
          console.log(result);
          if (this.searchTerm != "") {
            await this.performSearch();
          } else {
            await this.loadDocuments();
          }
        } catch (error) {
          console.log(error);
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    closePanel: function() {
      this.editingDoc = {
        visibility: "Internal",
      };
      this.showingUploadDocumentPanel = false;
    },
    onComplete: async function() {
      this.closePanel();
      if (this.searchTerm != "") {
        await this.performSearch();
      } else {
        await this.loadDocuments();
      }
    },
    onTableSortChanged: async function(sortOptions) {
      console.log(
        "[DocumentsTab] onTableSortChanged: Changed Sort: ",
        sortOptions
      );
      this.sortOptions = sortOptions;
      await this.loadDocuments();
    },
    onTablePageChanged: async function(direction) {
      console.log(
        "[DocumentsTab] onTablePageChanged: Page changed in the following direction: ",
        direction
      );
      this.paginationOptions.currentPage =
        this.paginationOptions.currentPage + direction;
      await this.loadDocuments();
    },
    onCategoryFilterChanged: async function() {
      await this.loadDocuments();
    },
    performSearch: async function() {
      if (this.searchTerm != "") {
        this.viewState = "Loading";
        try {
          let result = await this.InternalDocumentService.searchDocuments(
            this.searchTerm
          );
          console.log(result);
          this.publishedDocs = result.data;
          this.paginationOptions.currentPage = result.page;
          this.paginationOptions.totalPages = result.page_count;
          this.paginationOptions.totalRecords = result.count;
          this.isShowingSearchResults = true;
        } catch (error) {
          console.error(error);
        } finally {
          this.viewState = "Idle";
        }
      }
    },
    clearSearch: async function() {
      this.searchTerm = "";
      this.isShowingSearchResults = false;
      await this.loadDocuments();
    },
  },
};
</script>
